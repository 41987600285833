import { USER_ACCESS_TOKEN, USER_REFRESH_TOKEN } from '@constants/auth';
import Cookies from 'js-cookie';

export const tokenStorage = {
  getToken(key: string) {
    const localUserInfo = localStorage.getItem(key);

    return localUserInfo || null;
  },

  setToken(key: string, token: string) {
    localStorage.setItem(key, token);
    Cookies.set(key, token, { httpOnly: true, expires: 60 * 60 * 1000 });
  },

  getAccessToken() {
    return this.getToken(USER_ACCESS_TOKEN);
  },

  getRefreshToken() {
    return this.getToken(USER_REFRESH_TOKEN);
  },

  setAccessToken(token: string) {
    this.setToken(USER_ACCESS_TOKEN, token);
  },

  setRefreshToken(token: string) {
    this.setToken(USER_REFRESH_TOKEN, token);
  },

  removeToken() {
    localStorage.removeItem(USER_ACCESS_TOKEN);
  },

  cleanup() {
    localStorage.removeItem(USER_ACCESS_TOKEN);
    localStorage.removeItem(USER_REFRESH_TOKEN);
  },
};
