import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import auth from './locales/en/auth.json';
import common from './locales/en/common.json';
import dashboard from './locales/en/dashboard.json';

export const defaultNS = 'translation';

const resources = {
  en: {
    translation: {
      auth,
      common,
      dashboard,
    },
  },
} as const;

i18n.use(initReactI18next).init({
  defaultNS,
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
