import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const NoInvoicesMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6">{t('dashboard.invoicesMessage')}</Typography>
    </Box>
  );
};

export default memo(NoInvoicesMessage);
