import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from '@hooks/api/useUser';
import AppLayout from '@providers/layouts/AppLayout';

import Loading from '@components/Loading/Loading';

const PrivateRouteOutlet: FC = () => {
  const { isLoading } = useUser();

  if (isLoading) return <Loading />;

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default memo(PrivateRouteOutlet);
