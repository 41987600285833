import { COUNTDOWN_DATE } from '@constants/common';
import { addSeconds, format, parseISO } from 'date-fns';

export const formatCountdownTime = (countdown: number, message: string) => {
  if (countdown === 0) {
    return '0';
  }

  const endDate = addSeconds(COUNTDOWN_DATE, countdown);
  const formattedCountdown = format(endDate, 'mm:ss');

  return `(${message} ${formattedCountdown})`;
};

export const convertIsoToReadable = (isoDate: string) => {
  const dateObject = parseISO(isoDate);
  return format(dateObject, 'dd MMM, yy');
};

export const formatDateString = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, 'yyyy-MM-dd');
};
