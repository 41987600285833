import { FC, memo, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Tooltip } from '@mui/material';

const OverflowTip: FC<PropsWithChildren> = ({ children }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, [children]);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default memo(OverflowTip);
