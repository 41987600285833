import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { NOT_AVAILABLE } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { PreviewModalId } from '@pages/Invoices/modals/PreviewDocumentModal';
import { convertIsoToReadable } from '@utils/formatTime';

import DownloadIcon from '@mui/icons-material/Download';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import LoadingIconButton from '@components/LoadingIconButton';
import OverflowTip from '@components/OverflowTip';

export const useListColumns = (): GridColDef[] => {
  const [clickedId, setClickedId] = useState<number | null>(null);
  const { downloadFile, isLoadingDownload } = useDownloadFile();
  const { t } = useTranslation();

  return [
    {
      field: 'id',
      headerName: 'ID',
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      width: 80,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => <Typography variant="body2">{row.id}</Typography>,
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      hideable: false,
      sortable: false,
      resizable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" component="span">
            {row.recipient}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" component="span">
            {row.vendor}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'invoice_date',
      headerName: 'Invoice Date',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">
            {row.invoice_date && row.invoice_date.length > 0 ? convertIsoToReadable(row.invoice_date) : NOT_AVAILABLE}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">
            {row.due_date && row.due_date.length > 0 ? convertIsoToReadable(row.due_date) : NOT_AVAILABLE}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      minWidth: 150,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">
            {`${row.currency ? row.currency : ''}${row.currency_symbol ? row.currency_symbol : ''} ${row.total_amount}`}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      minWidth: 60,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" component="span">
            {row.paid ? t('common.invoice.paid') : t('common.invoice.notPaid')}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'preview',
      headerName: 'Preview',
      width: 100,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <IconButton
          onClick={async e => {
            e.stopPropagation();
            await NiceModal.show(PreviewModalId, { id: +row.id });
          }}
          aria-label="preview document button"
        >
          <VisibilityRoundedIcon />
        </IconButton>
      ),
    },
    {
      field: 'download',
      headerName: 'Download',
      width: 100,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <LoadingIconButton
          loaderSize="1.5rem"
          onClick={event => {
            event.stopPropagation();
            setClickedId(row.id);
            downloadFile({ id: row.id });
          }}
          loading={row.id === clickedId && isLoadingDownload}
          loaderColor="secondary"
          size="medium"
          color="primary"
          aria-label="download document button"
        >
          <DownloadIcon />
        </LoadingIconButton>
      ),
    },
  ];
};
