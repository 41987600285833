import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { StyledFooterBox } from '@components/Footer/styled';
import LogoWhite from '@components/LogoWhite';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterBox display="flex" justifyContent="space-between" alignItems="center">
      <LogoWhite />
      <Box display="flex" gap={1}>
        <Trans
          i18nKey={t('common.footer.rights')}
          components={{ bold: <Typography variant="subtitle1" /> }}
          values={{ year: new Date().getFullYear() }}
        />
      </Box>
    </StyledFooterBox>
  );
};

export default Footer;
