import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { DataPickerLocalizationProvider } from '@providers/dataPicker';
import { store } from '@store/store';

import Routing from '@/routes';

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <DataPickerLocalizationProvider>
          <NiceModal.Provider>
            <Routing />
          </NiceModal.Provider>
        </DataPickerLocalizationProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
