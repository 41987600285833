import { FC, memo, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { RootState } from '@store/store';

import Loading from '@components/Loading/Loading';

const PublicRegistrationGuard: FC<PropsWithChildren> = ({ children }) => {
  const authState = useSelector((state: RootState) => state.auth);

  if (!authState.isAuthenticated) {
    return children;
  }

  if (authState.isAuthenticated) {
    return <Navigate to={ROUTING.ROOT} replace />;
  }

  if (authState.isConfirmed === null) {
    return <Loading />;
  }

  return children;
};

export default memo(PublicRegistrationGuard);
