import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesRevertCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { ConfirmModalId } from '@/shared/ConfirmModal/ConfirmModal';

export const useRevert = (id: number) => {
  const { t } = useTranslation();
  const [revertMutation, { isLoading }] = useApiInvoicesRevertCreateMutation();
  const snackbar = useSnackbar();
  const revertField = useCallback(
    async (fieldName: string, itemId?: number) => {
      try {
        const result = await NiceModal.show(ConfirmModalId, {
          title: t('common.revert.confirmRevertTitle'),
        });
        if (result) {
          await revertMutation({
            id,
            invoiceRevertFieldRequest: { field_name: fieldName, ...(itemId && { item_id: itemId }) },
          });
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [id, revertMutation, snackbar, t],
  );
  return { revertField, isLoading };
};
