import { FC, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedInvoiceListRead } from '@api/api';
import { ROUTING } from '@constants/routing';
import NoInvoicesMessage from '@pages/Invoices/components/NoInvoicesMessage';
import { useListColumns } from '@pages/Invoices/hooks/useListColumns';

import { Box, LinearProgress } from '@mui/material';
import { DataGrid, GridEventListener, GridPaginationModel, GridRowSpacingParams } from '@mui/x-data-grid';

export interface Page {
  pageSize: number;
  page: number;
}

export interface InvoicesTableProps {
  invoices?: PaginatedInvoiceListRead;
  isLoading: boolean;
  handlePageChange: (newPaginationModel: GridPaginationModel) => void;
  paginationModel: Page;
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices, isLoading, handlePageChange, paginationModel }) => {
  const invoicesData = useMemo(() => invoices?.results || [], [invoices?.results]);
  const columns = useListColumns();
  const navigate = useNavigate();

  const props = useMemo(
    () => ({
      rowCount: invoices?.count,
      rows: invoicesData?.map(v => ({
        ...v,
      })),
      columns,
    }),
    [invoices?.count, invoicesData, columns],
  );

  const handleRowClick: GridEventListener<'rowClick'> = ({ row: { id } }) => {
    if (id) {
      navigate(`/${ROUTING.INVOICE_DETAILS}/${id}`);
    }
  };

  return (
    <Box>
      <DataGrid
        autoHeight
        paginationMode="server"
        pagination={invoicesData.length > 0 ? true : undefined}
        hideFooter={invoicesData.length === 0}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        rowSelection={false}
        rowHeight={68}
        loading={isLoading}
        showCellVerticalBorder={false}
        getRowSpacing={(params: GridRowSpacingParams) => ({
          top: params.isFirstVisible ? 0 : 5,
          bottom: params.isLastVisible ? 0 : 5,
        })}
        pageSizeOptions={[5, 10]}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoInvoicesMessage,
          ...(!invoices?.count && { columnHeaders: () => null }),
        }}
        onPaginationModelChange={handlePageChange}
        paginationModel={paginationModel}
        onRowClick={handleRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        {...props}
      />
    </Box>
  );
};

export default memo(InvoicesTable);
