import { ApiInvoicesChangesListApiResponse, InvoiceRead } from '@api/api';
import { ITEMS_FIELD } from '@pages/InvoiceDetails/constants';

export const currencyCodes = ['USD', 'AUD', 'GBP', 'EUR'];
export const currencySymbols = ['$', '£', '€'];

function parseItems(value: string): any[] {
  const jsonString = value.replace(/'/g, '"').replace(/None/g, 'null').replace(/\\n/g, '\\n');
  return JSON.parse(jsonString);
}

function compareItems(oldItems: any[], newItems: any[], prefix: string): string[] {
  const changes: string[] = [];

  oldItems.forEach((oldItem, index) => {
    const newItem = newItems[index];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in oldItem) {
      if (oldItem[key] !== newItem[key]) {
        changes.push(`${prefix}.${index}.${key}`);
      }
    }
  });

  return changes;
}

export function getChangedFields(changes: ApiInvoicesChangesListApiResponse): string[] {
  const changedFields: string[] = [];

  changes.forEach(change => {
    if (change.field_name === ITEMS_FIELD) {
      const oldItems = parseItems(change.old_value as string);
      const newItems = parseItems(change.new_value as string);
      const nestedChanges = compareItems(oldItems, newItems, ITEMS_FIELD);
      changedFields.push(...nestedChanges);
    } else if (change.old_value !== change.new_value) {
      changedFields.push(change.field_name);
    }
  });

  return changedFields;
}

export function getItemIndex(
  itemId: number,
  fieldName: string,
  items?: InvoiceRead['items'],
  changesData?: ApiInvoicesChangesListApiResponse,
): number {
  if (!items || !changesData || !fieldName) return -1;

  // Filter all changes that match the itemId and fieldName
  const relatedChanges = changesData.filter(change => change.item_id === itemId && change.field_name === fieldName);

  if (relatedChanges.length === 0) return -1;

  // Find the index of the item in the items array
  return items.findIndex(item => item.id === itemId);
}
