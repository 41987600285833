import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InvoiceItem, InvoiceRead } from '@api/api';
import { NOT_AVAILABLE } from '@constants/common';
import { StyledAccordionSummary, StyledPaper } from '@pages/InvoiceDetails/styled';
import { convertIsoToReadable } from '@utils/formatTime';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';

const Details: FC<{ data?: InvoiceRead }> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <StyledPaper elevation={6}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.recipientField"
            components={{ bold: <Typography variant="subtitle1" component="span" /> }}
          />{' '}
          {data?.recipient}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.vendorField"
            components={{ bold: <Typography variant="subtitle1" component="span" /> }}
          />{' '}
          {data?.vendor}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.invoiceDateField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.invoice_date && data?.invoice_date.length > 0
            ? convertIsoToReadable(data?.invoice_date)
            : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.dueDateField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.due_date && data?.due_date.length > 0 ? convertIsoToReadable(data?.due_date) : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.updatedDateField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.updated_at && data?.updated_at.length > 0 ? convertIsoToReadable(data?.updated_at) : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.serviceStartDateField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.service_start_date && data?.service_start_date.length > 0
            ? convertIsoToReadable(data?.service_start_date)
            : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.serviceEndDateField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.service_end_date && data?.service_end_date.length > 0
            ? convertIsoToReadable(data?.service_end_date)
            : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.abnField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.ibn && data?.ibn.length > 0 ? data.ibn : NOT_AVAILABLE}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.customerNameField"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.customer_name}
        </Typography>
        <Typography>
          <Trans
            i18nKey="dashboard.invoiceDetails.paymentStatus"
            components={{
              bold: <Typography variant="subtitle1" component="span" />,
            }}
          />{' '}
          {data?.paid ? t('common.invoice.paid') : t('common.invoice.notPaid')}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="subtitle1">{t('dashboard.invoiceDetails.servicesTitle')}</Typography>
        {data?.items?.map((item: InvoiceItem, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} display="flex" gap={1} mt={1} mb={1}>
            {item.description && (
              <Typography
                variant="body1"
                component="span"
              >{`${t('dashboard.invoiceDetails.descriptionTitle')}: ${item.description}`}</Typography>
            )}
            {item.unit_price && (
              <Typography
                variant="body1"
                component="span"
              >{`${t('dashboard.invoiceDetails.unitPriceTitle')}: ${item.currency_code}${item.currency_symbol} ${item.amount}`}</Typography>
            )}
            {item.amount && (
              <Typography
                variant="body1"
                component="span"
              >{`${t('dashboard.invoiceDetails.totalPriceTitle')}: ${item.currency_code}${item.currency_symbol} ${item.amount}`}</Typography>
            )}
          </Box>
        ))}
        <Typography
          variant="subtitle1"
          mt={4}
          mb={2}
        >{`${t('dashboard.invoiceDetails.totalTaxTitle')}: ${data?.currency ? data?.currency : ''}${data?.currency_symbol ? data?.currency_symbol : ''} ${data?.total_tax ? data?.total_tax : 0}`}</Typography>
        <Typography variant="subtitle1">{`${t('dashboard.invoiceDetails.totalAmountTitle')}: ${data?.currency ? data?.currency : ''}${data?.currency_symbol ? data?.currency_symbol : ''} ${data?.total_amount}`}</Typography>
      </Box>
      <Box mt={4}>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">{t('dashboard.invoiceDetails.rawDataTitle')}</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      </Box>
    </StyledPaper>
  );
};

export default memo(Details);
