import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box, Button, LinearProgress } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledDocViewerWrapper, StyledModal } from '../styled';

import '@cyntler/react-doc-viewer/dist/index.css';

export interface DocumentResponse {
  blob: Blob;
  filename: string;
}

const PreviewModal = NiceModal.create(({ id }: { id: number }) => {
  const [docs, setDocs] = useState<Array<DocumentResponse>>([]);
  const { t } = useTranslation();
  const modal = useModal();
  const { isLoadingDownload, isLoadingPreview, downloadFile, downloadPreview } = useDownloadFile();
  const snackbar = useSnackbar();

  const handleDownload = async () => {
    await downloadFile({ id });
    modal.resolve(true);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const res = await downloadPreview({ id });
        if (res) {
          setDocs([res]);
        }
      } catch (error) {
        snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    };

    fetchDocument();
  }, []);

  return (
    <StyledModal open={modal.visible} size="xl" onClose={handleCancelClick}>
      <Box padding="24px 48px" minWidth={560}>
        <Box display="flex" justifyContent="space-between">
          <Button color="secondary" onClick={handleCancelClick}>
            {t('common.back')}
          </Button>
          <LoadingButton
            disabled={isLoadingPreview}
            loading={isLoadingDownload}
            variant="contained"
            color="success"
            onClick={handleDownload}
          >
            {t('common.download')}
          </LoadingButton>
        </Box>
        <Box mt={1} minHeight={300}>
          {isLoadingPreview ? (
            <LinearProgress />
          ) : (
            <StyledDocViewerWrapper>
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs.map(file => ({
                  uri: window.URL.createObjectURL(file.blob),
                  fileName: file.filename,
                }))}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: false,
                    retainURLParams: true,
                  },
                }}
                style={{ height: 700 }}
              />
            </StyledDocViewerWrapper>
          )}
        </Box>
      </Box>
    </StyledModal>
  );
});

export const PreviewModalId = 'PreviewModal';

NiceModal.register(PreviewModalId, PreviewModal);
