import { FC, memo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ParserUploadCreateApiArg } from '@api/api';
import { ERROR, USER_ACCESS_TOKEN } from '@constants/auth';
import { API_ERROR_MSG_PATH, MAX_UPLOAD_FILES } from '@constants/common';
import { UPLOAD_FILE_ERROR } from '@constants/errors';
import { StyledGrid } from '@pages/UploadInvoice/styled';
import { getErrorMessage } from '@utils/getMessage';
import { tokenStorage } from '@utils/tokenStorage';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

const baseUrl = import.meta.env.VITE_APP_API_URL;
const uploadFileMutation = async ({ body }: ParserUploadCreateApiArg) => {
  const formData = new FormData();
  if (body.file) {
    formData.append('file', body.file);
  }
  const token = tokenStorage.getToken(USER_ACCESS_TOKEN);

  const response = await fetch(`${baseUrl}/parser/upload/`, {
    method: 'POST',
    body: formData,
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

  return response.json();
};

const UploadInvoice: FC = () => {
  const { t } = useTranslation();
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const onDrop = (acceptedFiles: File[]) => {
    setAddedFiles([...addedFiles, ...acceptedFiles]);
  };

  const handleBack = () => navigate(-1);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: MAX_UPLOAD_FILES,
  });

  const handleRemoveFile = (fileIndex: number) => {
    setAddedFiles(addedFiles.filter((_, index) => index !== fileIndex));
  };

  const handleUpload = async () => {
    try {
      setUploadingFiles(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const file of addedFiles) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFileMutation({ body: { file } });
      }
      snackbar.enqueueSnackbar(t('dashboard.uploadSuccess'), { variant: 'success' });
    } catch (error) {
      snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      setAddedFiles([]);
      setUploadingFiles(false);
    }
  };

  useEffect(() => {
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.TOO_MANY_FILES) {
      snackbar.enqueueSnackbar(t('dashboard.dragAndDropLimitNotification', { maxFiles: MAX_UPLOAD_FILES }), {
        variant: 'warning',
      });
    }
    if (fileRejections && fileRejections[0]?.errors?.[0].code === UPLOAD_FILE_ERROR.INVALID_FILE_TYPE) {
      snackbar.enqueueSnackbar(fileRejections[0].errors[0].message, {
        variant: 'warning',
      });
    }
  }, [fileRejections, snackbar, t]);

  return (
    <Box>
      <Typography variant="h4">{t('dashboard.navigation.uploadInvoice')}</Typography>
      <Grid container spacing={2} my={1}>
        <Grid item xs={6}>
          <Box {...getRootProps()} aria-label="Drag and drop zone">
            <input {...getInputProps()} aria-label="File input" />
            <Box p={10} border="1px dashed grey" textAlign="center">
              <Typography>{t('dashboard.dragAndDropTitle')}</Typography>
              <Typography>({t('dashboard.dragAndDropFileTypes', { maxFiles: MAX_UPLOAD_FILES })})</Typography>
            </Box>
          </Box>
          <Box mt={3} display="flex" gap={3}>
            <Button
              onClick={handleBack}
              disabled={uploadingFiles}
              color="secondary"
              variant="contained"
              aria-label="go back button"
            >
              {t('common.goBack')}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={addedFiles.length === 0}
              loading={uploadingFiles}
              aria-label={t('dashboard.uploadFiles')}
              aria-disabled={addedFiles.length === 0}
            >
              {t('dashboard.uploadFiles')}
            </LoadingButton>
          </Box>
        </Grid>
        <StyledGrid item xs={6}>
          <List aria-label="List of added files">
            {addedFiles.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>
                <Box display="flex" alignItems="center" width="100%">
                  <Typography>{file.name}</Typography>
                  <Typography variant="caption" color="textSecondary" ml={1}>
                    ({(file.size / 1024).toFixed(2)}KB)
                  </Typography>
                  <IconButton onClick={() => handleRemoveFile(index)} aria-label={`Remove ${file.name}`}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </StyledGrid>
      </Grid>
    </Box>
  );
};

export default memo(UploadInvoice);
