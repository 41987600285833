import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { api, useAuthSignoutCreateMutation } from '@api/api';
import { logoutSuccess } from '@store/authSlice';
import { authStorage } from '@utils/authStorage';
import { tokenStorage } from '@utils/tokenStorage';

export const useLogout = () => {
  const dispatch = useDispatch();
  const [logoutMutation] = useAuthSignoutCreateMutation();

  const logout = useCallback(() => {
    tokenStorage.cleanup();
    authStorage.removeSignup();
    dispatch(logoutSuccess());
    dispatch(api.util.resetApiState());
    logoutMutation();
  }, [dispatch, logoutMutation]);

  return {
    logout,
  };
};
