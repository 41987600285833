import { Box, BoxProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';


export const StyledFooterBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.contrast,
  padding: 40,
  color: theme.colors.white,
  position: 'relative'
}))
