import { useState } from 'react';
import { ApiInvoicesDownloadRetrieveApiArg } from '@api/api';
import { ERROR, USER_ACCESS_TOKEN } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { getErrorMessage } from '@utils/getMessage';
import { tokenStorage } from '@utils/tokenStorage';
import { useSnackbar } from 'notistack';

const baseUrl = import.meta.env.VITE_APP_API_URL;

const getFilename = (response: Response) => {
  const contentDisposition = response.headers.get('content-disposition');
  return contentDisposition?.match(/filename="(.+?)"/)?.[1] || 'invoice';
};

const getFiletype = (response: Response) => response.headers.get('content-type');

const useDownloadFile = () => {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [error, setError] = useState(null);
  const snackbar = useSnackbar();
  const token = tokenStorage.getToken(USER_ACCESS_TOKEN);

  const fetchDocument = async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
    const response = await fetch(`${baseUrl}/api/invoices/${payload.id}/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Network error');
    }

    return response;
  };

  const downloadFile = async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
    setLoadingDownload(true);
    setError(null);
    try {
      const response = await fetchDocument(payload);
      const blob = await response.blob();
      const filename = getFilename(response);
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(err as any);
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      setLoadingDownload(false);
    }
  };

  const downloadPreview = async (payload: ApiInvoicesDownloadRetrieveApiArg) => {
    setLoadingPreview(true);
    setError(null);
    try {
      const response = await fetchDocument(payload);
      const blob = await response.blob();
      const filename = getFilename(response);
      const filetype = getFiletype(response);

      return { blob, filename, filetype };
    } catch (err) {
      setError(err as any);
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      return null;
    } finally {
      setLoadingPreview(false);
    }
  };

  return {
    downloadFile,
    downloadPreview,
    isLoadingDownload: loadingDownload,
    isLoadingPreview: loadingPreview,
    isError: error,
  };
};

export default useDownloadFile;
