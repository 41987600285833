/* eslint-disable no-param-reassign */
import { JwtRead } from '@api/api';
import { invalidateAllTags } from '@api/baseApi';
import { USER_ACCESS_TOKEN } from '@constants/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tokenStorage } from '@utils/tokenStorage';

interface AuthState {
  isAuthenticated: boolean;
  isConfirmed: boolean | null;
  authResponse?: JwtRead | null;
}

const initialState: AuthState = {
  isAuthenticated: !!tokenStorage.getToken(USER_ACCESS_TOKEN),
  isConfirmed: null,
  authResponse: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state: AuthState, action: PayloadAction<JwtRead>) {
      invalidateAllTags();
      state.authResponse = action.payload;
      state.isAuthenticated = true;
      state.isConfirmed = null;
    },
    logoutSuccess(state: AuthState) {
      state.authResponse = null;
      state.isAuthenticated = false;
      state.isConfirmed = null;
    },
    signupSuccess(state: AuthState) {
      state.isConfirmed = false;
    },
    confirmSuccess(state: AuthState) {
      state.isConfirmed = true;
    },
    clearConfirm(state: AuthState) {
      state.isConfirmed = false;
    },
  },
});

export const { loginSuccess, logoutSuccess, confirmSuccess, clearConfirm, signupSuccess } = authSlice.actions;
export default authSlice.reducer;
