import { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { YES } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { RootState } from '@store/store';
import { authStorage } from '@utils/authStorage';

const PrivateRoutes: FC = () => {
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  const signupStatus = authStorage.getSignup();

  useEffect(() => {
    if (signupStatus === YES && !authState.isAuthenticated) {
      navigate(`/${ROUTING.CONFIRM_EMAIL}`, { replace: true });
    }
  }, [navigate, authState.isConfirmed, signupStatus, authState.isAuthenticated]);

  if (signupStatus !== YES) return <Navigate to={ROUTING.LOGIN} replace />;

  return <Outlet />;
};

export default memo(PrivateRoutes);
