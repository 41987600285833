import { FC, memo } from 'react';

import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import IconButton from '@mui/material/IconButton';

const HistoryRevert: FC<{ handleRevert: () => void }> = ({ handleRevert }) => (
  <IconButton aria-label="revert field button" onClick={handleRevert}>
    <HistoryRoundedIcon />
  </IconButton>
);

export default memo(HistoryRevert);
