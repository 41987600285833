import { AccordionSummary, AccordionSummaryProps, Paper, PaperProps, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPaper = styled(Paper)<PaperProps>({
  padding: '24px',
  margin: '24px',
});

export const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>({
  '&.MuiButtonBase-root': {
    padding: 0,
  },
});

export const StyledTextField = styled(TextField)<TextFieldProps>({
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});
