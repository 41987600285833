import { FC, memo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useApiInvoicesChangesListQuery,
  useApiInvoicesRetrieveQuery,
  useApiInvoicesUpdatePartialUpdateMutation,
} from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import useDownloadFile from '@hooks/api/useDownloadFile';
import Details from '@pages/InvoiceDetails/components/Details';
import UpdateDetailsForm from '@pages/InvoiceDetails/components/UpdateDetailsForm';
import { useInvoiceDetailsContext } from '@pages/InvoiceDetails/context';
import { UpdateSchema } from '@pages/InvoiceDetails/schema/types';
import { formatDateString } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box, Button, LinearProgress } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

const InvoiceDetails: FC = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { invoiceId, isLoadingRevert } = useInvoiceDetailsContext();
  const { downloadFile, isLoadingDownload: isLoadingFile } = useDownloadFile();
  const [invoiceUpdateMutation, { isLoading: isLoadingUpdate, error: updateError }] =
    useApiInvoicesUpdatePartialUpdateMutation();
  const {
    data: invoiceData,
    isLoading: isLoadingInvoices,
    error,
  } = useApiInvoicesRetrieveQuery({ id: invoiceId ? +invoiceId : 1 });
  const { data: changesData, isLoading: isLoadingChanges } = useApiInvoicesChangesListQuery({
    invoiceId: invoiceId ? +invoiceId : 1,
  });
  const { editInvoice, toggleEdit, invoiceForm } = useInvoiceDetailsContext();

  const handleBack = () => navigate(-1);
  const handleDownload = () => downloadFile({ id: +invoiceId });
  const handlePay = async () => {
    try {
      await invoiceUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: {
          ...invoiceData,
          paid: true,
        },
      });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };
  const handleUpdateInvoiceFields: SubmitHandler<UpdateSchema> = async ({
    vendor,
    invoice_date,
    due_date,
    updated_at,
    service_start_date,
    service_end_date,
    customer_name,
    customer_address,
    ibn,
    items,
    sub_total,
    currency,
    currency_symbol,
    total_tax,
    total_amount,
  }) => {
    try {
      const payload = {
        ...(vendor && { vendor }),
        ...(invoice_date && { invoice_date: formatDateString(new Date(invoice_date).toISOString()) }),
        ...(due_date && { due_date: formatDateString(new Date(due_date).toISOString()) }),
        ...(updated_at && { updated_at: new Date(updated_at).toISOString() }),
        ...(service_start_date && { service_start_date: new Date(service_start_date).toISOString() }),
        ...(service_end_date && { service_end_date: new Date(service_end_date).toISOString() }),
        ...(customer_name && { customer_name }),
        ...(customer_address && { customer_address }),
        ...(ibn && { ibn }),
        ...(items &&
          items.length > 0 && {
            items: items?.map(item => ({
              id: item.id,
              amount: item.amount,
              unit_price: Number(item.unit_price).toFixed(2),
              quantity: item.quantity,
              description: item.description,
              currency_symbol: item.currency_symbol,
              currency_code: item.currency_code,
            })),
          }),
        ...(sub_total && { sub_total }),
        ...(currency && { currency }),
        ...(currency_symbol && { currency_symbol }),
        ...(total_tax && { total_tax }),
        ...(total_amount && { total_amount }),
      };
      await invoiceUpdateMutation({ id: +invoiceId, patchedInvoiceUpdateRequest: payload });
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      if (!updateError) {
        toggleEdit();
      }
    }
  };
  const handleResetForm = useCallback(
    () =>
      invoiceData &&
      invoiceForm.reset({
        recipient: invoiceData.recipient || undefined,
        vendor: invoiceData.vendor_name || undefined,
        invoice_date: invoiceData.invoice_date ? new Date(invoiceData.invoice_date) : undefined,
        due_date: invoiceData.due_date ? new Date(invoiceData.due_date) : undefined,
        updated_at: invoiceData.updated_at ? new Date(invoiceData.updated_at) : undefined,
        service_start_date: invoiceData.service_start_date ? new Date(invoiceData.service_start_date) : undefined,
        service_end_date: invoiceData.service_end_date ? new Date(invoiceData.service_end_date) : undefined,
        customer_name: invoiceData.customer_name ? invoiceData.customer_name : undefined,
        customer_address: invoiceData.customer_address || undefined,
        ibn: invoiceData.ibn || undefined,
        items: invoiceData.items.length > 0 ? invoiceData.items : [],
        sub_total: invoiceData.sub_total ? invoiceData.sub_total : undefined,
        total_tax: invoiceData.total_tax ? invoiceData.total_tax : undefined,
        total_amount: invoiceData.total_amount ? invoiceData.total_amount : undefined,
        currency_symbol: invoiceData.currency_symbol || '$',
        currency: invoiceData.currency || 'AUD',
      }),
    [invoiceData, invoiceForm],
  );

  const isLoading = [isLoadingChanges, isLoadingInvoices].some(Boolean);

  if (error) {
    snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
  }

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
  }, [handleResetForm, invoiceData, invoiceForm]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoiceDetails')}</title>
      </Helmet>

      <Box width="100%" ml={3} display="flex" gap={3}>
        <Button
          onClick={handleBack}
          color="secondary"
          variant="contained"
          aria-label="go back button"
          disabled={isLoadingRevert}
        >
          {t('common.goBack')}
        </Button>
        <LoadingButton
          loading={isLoadingFile}
          onClick={handleDownload}
          color="primary"
          variant="contained"
          aria-label="download invoice button"
          disabled={editInvoice || isLoadingRevert}
        >
          {t('common.download')}
        </LoadingButton>
        {!invoiceData?.paid && (
          <LoadingButton
            sx={{ boxShadow: 'none', '&.Mui-disabled': { boxShadow: 'none' } }}
            loading={isLoadingUpdate}
            onClick={handlePay}
            color="warning"
            variant="contained"
            aria-label="invoice payment button"
            disabled={editInvoice || isLoadingRevert}
          >
            {t('common.invoice.performPayment')}
          </LoadingButton>
        )}
        <Button
          onClick={() => {
            toggleEdit();
            handleResetForm();
          }}
          color="info"
          variant="contained"
          aria-label="toggle edit invoice button"
          disabled={isLoadingRevert}
        >
          {editInvoice ? t('common.cancelEdit') : t('common.editInvoice')}
        </Button>
      </Box>
      {!editInvoice && <Details data={invoiceData} />}
      {editInvoice && (
        <FormProvider {...invoiceForm}>
          <form onSubmit={invoiceForm.handleSubmit(handleUpdateInvoiceFields, err => console.log(err))}>
            <UpdateDetailsForm data={invoiceData} loading={isLoadingUpdate} changesData={changesData} />
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default memo(InvoiceDetails);
