import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomUserDetailsRead } from '@api/api';
import { ROUTING } from '@constants/routing';
import { account } from 'src/_mock/account';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const MENU_OPTIONS = [
  {
    label: 'Dashboard',
    path: '',
  },
  {
    label: 'Invoices',
    path: ROUTING.INVOICES,
  },
];

const AccountPopover: FC<{ userData?: CustomUserDetailsRead; handleLogout: () => void }> = ({
  userData,
  handleLogout,
}) => {
  const [open, setOpen] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleRedirect = (path: string) => () => {
    handleClose();
    navigate(`/${path}`);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: theme => alpha(theme.palette.grey[500], 0.08),
          // @ts-ignore
          ...(open && {
            background: theme =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
        aria-expanded={!!open}
        aria-label="click on user icon to expand menu"
      >
        <Avatar
          aria-label="user avatar"
          src={account.photoURL}
          alt={userData?.username}
          sx={{
            width: 36,
            height: 36,
            border: theme => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userData?.username.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap aria-label="username">
            {userData?.username}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap aria-label="user email">
            {userData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {MENU_OPTIONS.map(({ label, path }) => (
          <MenuItem
            key={label}
            onClick={handleRedirect(path)}
            aria-label={label}
            aria-details={`click for redirect to ${label}`}
          >
            {label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
          aria-label={t('auth.logout')}
          aria-details="click to logout from application"
        >
          {t('auth.logout')}
        </MenuItem>
      </Popover>
    </>
  );
};

export default memo(AccountPopover);
