import SvgColor from '@/components/SvgColor';

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'invoices',
    path: '/invoices',
    icon: icon('ic_cart'),
  },
];

export default navConfig;
