import { FC, memo, PropsWithChildren, useState } from 'react';

import Box from '@mui/material/Box';

import Header from './components/Header';
import Main from './components/Main';
import Navigation from './components/Navigation';
import Footer from '@components/Footer';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);
  return (
    <>
      <main style={{ height: 'calc(100vh - 115px)' }}>
        <Header onOpenNav={() => setOpenNav(true)} />

        <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
          <Navigation openNav={openNav} onCloseNav={() => setOpenNav(false)} />

          <Main>{children}</Main>
        </Box>
      </main>
      <Footer />
    </>
  );
};

export default memo(AppLayout);
