import { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const MuiAppBar: {
  defaultProps?: ComponentsProps['MuiAppBar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAppBar'];
  variants?: ComponentsVariants['MuiAppBar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: 'none',
      backgroundColor: theme.colors.white,
      color: theme.colors.text,
    }),
  },
};
