import { FC, PropsWithChildren } from 'react';
import { YES } from '@constants/common';
import { useUser } from '@hooks/api/useUser';
import { authStorage } from '@utils/authStorage';

import { Box } from '@mui/material';

const PrivatePreviewLayout: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useUser();
  const hasRights = user || authStorage.getSignup() === YES;

  return <Box style={!hasRights ? { visibility: 'hidden' } : undefined}>{hasRights ? children : null}</Box>;
};

export default PrivatePreviewLayout;
