import { FC, memo, useEffect } from 'react';
import { useUser } from '@hooks/api/useUser';
import { usePathname } from '@hooks/usePathname';
import { useResponsive } from '@hooks/useResponsive';
import RouterLink from '@routes/components/RouterLink';
import { account } from 'src/_mock/account';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Logo from '@components/Logo';
import Scrollbar from '@components/Scrollbar';

import { NAV } from '../config';
import navConfig from '../config/navigation';

interface NavItemProps {
  item: {
    icon: JSX.Element;
    path: string;
    title: string;
  };
}

const NavItem: FC<NavItemProps> = ({ item }) => {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      role="menuitem"
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: theme => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span" aria-label={`link to ${item.title}`}>
        {item.title}{' '}
      </Box>
    </ListItemButton>
  );
};

const Navigation: FC<{ openNav: boolean; onCloseNav: () => void }> = ({ openNav, onCloseNav }) => {
  const pathname = usePathname();
  const { data } = useUser();

  const upLg = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: theme => alpha(theme.palette.grey[500], 0.12),
      }}
      role="none"
    >
      <Avatar src={account.photoURL} alt="photoURL" aria-label="user photo" role="img" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2" aria-label="username field">
          {data?.username}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }} aria-label="list of pages" role="menu">
      {navConfig.map(item => (
        <NavItem key={item.title} item={item} arai-label={item.title} />
      ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      aria-label="navigation scrollbar"
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4 }} aria-label="company logo" />

      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} role="none" />
    </Scrollbar>
  );

  return (
    <Box
      aria-label="app navigation bar"
      aria-roledescription="navigation bar to traverse between pages"
      role="navigation"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          aria-label="large navigation bar"
          aria-roledescription="large size navigation bar to traverse between pages"
          role="navigation"
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        // eslint-disable-next-line jsx-a11y/role-supports-aria-props
        <Drawer
          aria-label="non large navigation drawer"
          aria-roledescription="small navigation bar to traverse between pages"
          role="navigation"
          aria-expanded={openNav}
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default memo(Navigation);
