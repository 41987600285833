import * as yup from 'yup';

export const updateInvoiceSchema = yup.object().shape({
  recipient: yup.string(),
  vendor: yup.string(),
  invoice_date: yup.date(),
  due_date: yup.string(),
  updated_at: yup.string(),
  service_start_date: yup.string(),
  service_end_date: yup.string(),
  customer_name: yup.string(),
  customer_address: yup.string(),
  ibn: yup.string(),
  items: yup.array().of(
    yup.object().shape({
      amount: yup.number(),
      quantity: yup.number(),
      description: yup.string(),
      currency_symbol: yup.string(),
      currency_code: yup.string(),
      unit_price: yup.string(),
    }),
  ),
  sub_total: yup.string(),
  total_tax: yup.string(),
  total_amount: yup.string(),
  currency_symbol: yup.string(),
  currency: yup.string(),
});
