import { FC, memo, PropsWithChildren } from 'react';
import { useResponsive } from '@hooks/useResponsive';

import Box from '@mui/material/Box';

import { HEADER, NAV } from '../config';

const SPACING = 8;

const Main: FC<PropsWithChildren> = ({ children, sx, ...other }: any) => {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      aria-label="application current view"
      component="nav"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        px: 3,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default memo(Main);
