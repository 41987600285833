import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';

import { Box, Typography } from '@mui/material';

const Dashboard: FC = () => {
  const { data } = useUser();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography aria-label="dashboard page" variant="h4">
        {t('dashboard.navigation.dashboard')}
      </Typography>
      <Typography variant="subtitle2" aria-label="user invoice email">{`Email: ${data?.invoice_email}`}</Typography>
    </Box>
  );
};

export default memo(Dashboard);
