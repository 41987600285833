import { HEADER_HEIGHT_DESKTOP } from '@constants/common';

import { AppBar, Box, Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapperContent = styled(Box)({
  minHeight: `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: `${HEADER_HEIGHT_DESKTOP}px`,
  position: 'relative',
  backgroundImage: 'url("./assets/images/regbg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 60%',
  backgroundPosition: 'center bottom',
});

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: `${HEADER_HEIGHT_DESKTOP}px`,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  boxShadow: 'none',
}));

export const StyledBox = styled(Box)({
  marginBottom: '-50px',
});

export const StyledInnerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '55px 75px 0px',
});

export const StyledButton = styled(Button)<ButtonProps>({
  padding: '10px 40px',
  alignSelf: 'end',
});
