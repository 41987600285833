import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { INVOICE_ID } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { CreateIdProvider } from '@pages/CreateID/context';
import Dashboard from '@pages/Dashboard';
import InvoiceDetails from '@pages/InvoiceDetails';
import { InvoiceDetailsProvider } from '@pages/InvoiceDetails/context';
import Invoices from '@pages/Invoices';
import UploadInvoice from '@pages/UploadInvoice';
import PrivatePreviewLayout from '@providers/layouts/PrivatePreviewLayout/PrivatePreviewLayout';

import Loading from '@components/Loading/Loading';
import RegistrationLayout from '@components/RegistrationView';

import PublicRegistrationGuard from './guards/PublicRegistrationGuard';
import PrivateRouteOutlet from './PrivateRouteOutlet';
import PrivateRoutes from './PrivateRoutes';

const Login = lazy(() => import('@pages/Login'));
const Signup = lazy(() => import('@pages/Signup'));
const ConfirmEmail = lazy(() => import('@pages/ConfirmEmail'));
const ForgotPassword = lazy(() => import('@pages/ForgotPassword'));
const ResetPassword = lazy(() => import('@pages/ResetPassword'));
const NotFound = lazy(() => import('@pages/NotFound'));
const CreateCapsureId = lazy(() => import('@pages/CreateID'));

const PUBLIC_ROUTES = [
  { path: ROUTING.LOGIN, Component: Login },
  { path: ROUTING.SIGNUP, Component: Signup },
  { path: ROUTING.FORGOT_PASSWORD, Component: ForgotPassword },
  { path: ROUTING.RESET_PASSWORD, Component: ResetPassword },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        element: (
          <PrivatePreviewLayout>
            <PrivateRouteOutlet />
          </PrivatePreviewLayout>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: ROUTING.INVOICES,
            element: <Invoices />,
          },
          {
            path: `${ROUTING.INVOICE_DETAILS}/:${INVOICE_ID}`,
            element: (
              <InvoiceDetailsProvider>
                <InvoiceDetails />
              </InvoiceDetailsProvider>
            ),
          },
          {
            path: ROUTING.INVOICE_UPLOAD,
            element: <UploadInvoice />,
          },
        ],
      },
      {
        path: ROUTING.CONFIRM_EMAIL,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <ConfirmEmail />
            </RegistrationLayout>
          </Suspense>
        ),
      },
      {
        path: ROUTING.CREATE_ID,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <CreateIdProvider>
                <CreateCapsureId />
              </CreateIdProvider>
            </RegistrationLayout>
          </Suspense>
        ),
      },
    ],
  },
  ...PUBLIC_ROUTES.map(({ path, Component }) => ({
    path,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicRegistrationGuard>
          <RegistrationLayout>
            <Component key={path} />
          </RegistrationLayout>
        </PublicRegistrationGuard>
      </Suspense>
    ),
  })),
  {
    path: '*',
    element: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
  },
]);

const Routing = () => <RouterProvider router={router} />;

export default Routing;
