import { FC, memo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { authStorage } from '@utils/authStorage';
import { tokenStorage } from '@utils/tokenStorage';

import { Container, Typography } from '@mui/material';
import Logo from '@components/Logo';

import { StyledAppBar, StyledBox, StyledButton, StyledInnerBox, StyledWrapperContent } from './styled';

const RegistrationView: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isResetPasswordPage = location.pathname.includes(ROUTING.RESET_PASSWORD);
  const toLoginPage = location.pathname.includes(ROUTING.SIGNUP || ROUTING.CONFIRM_EMAIL);

  const getButtonLocale = () => (toLoginPage ? t('auth.login') : t('auth.createAccount'));
  const handleRedirectToPage = (route: string) => {
    navigate(`/${route}`, { replace: true });
  };
  const handleRedirect = () => {
    tokenStorage.cleanup();
    authStorage.removeSignup();
    return toLoginPage ? handleRedirectToPage(ROUTING.LOGIN) : handleRedirectToPage(ROUTING.SIGNUP);
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledInnerBox>
          <Logo />
          <StyledBox display="flex" flexDirection="column" gap={2}>
            {!isResetPasswordPage && (
              <>
                <Typography>
                  {toLoginPage ? t('common.haveAnAccountCaptionText') : t('common.accountCaptionText')}
                </Typography>
                <StyledButton
                  disableRipple
                  disableTouchRipple
                  variant="contained"
                  color="secondary"
                  sx={{ boxShadow: 2 }}
                  onClick={handleRedirect}
                >
                  {getButtonLocale()}
                </StyledButton>
              </>
            )}
          </StyledBox>
        </StyledInnerBox>
      </StyledAppBar>
      <main aria-roledescription="registration-container">
        <StyledWrapperContent>
          <Container>{children}</Container>
        </StyledWrapperContent>
      </main>
    </>
  );
};

export default memo(RegistrationView);
