import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Box, Button, Typography } from '@mui/material';

import { StyledModal } from '../styles';

const ConfirmModal = NiceModal.create(
  ({ title, singleButton = false, subtitle }: { title: string; singleButton?: boolean; subtitle?: string }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onClose={handleCancelClick}>
        <Box padding="24px 48px">
          <Typography variant="h6" mb="24px" textAlign="center">
            {t(title)}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" mb="24px" textAlign="center">
              {subtitle}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" gap="12px">
            {!singleButton && (
              <Button fullWidth color="secondary" onClick={handleCancelClick}>
                {t('common.cancel')}
              </Button>
            )}
            <Button fullWidth variant="outlined" color="primary" onClick={handleConfirmClick}>
              {t('common.confirm')}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const ConfirmModalId = 'ConfirmModal';

NiceModal.register(ConfirmModalId, ConfirmModal);
