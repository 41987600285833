import React, { FC } from 'react';

import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

const Loading: FC<{ sx?: SxProps<Theme> }> = props => (
  <Box
    p="75px"
    height="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    position="absolute"
    {...props}
  >
    <CircularProgress size="7rem" color="primary" />
  </Box>
);
export default Loading;
