import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRevert } from '@hooks/api/useRevert';
import { updateInvoiceSchema } from '@pages/InvoiceDetails/schema';
import { UpdateSchema } from '@pages/InvoiceDetails/schema/types';

const InvoiceDetailsContext = createContext<ReturnType<typeof useInvoiceDetailsContextValue> | null>(null);

const useInvoiceDetailsContextValue = () => {
  const [editInvoice, setEditInvoice] = useState(false);
  const { invoiceId = '1' } = useParams();
  const { revertField, isLoading } = useRevert(+invoiceId);

  const invoiceForm = useForm<UpdateSchema>({
    // @ts-ignore
    resolver: yupResolver(updateInvoiceSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const toggleEdit = useCallback(() => setEditInvoice(!editInvoice), [editInvoice]);

  return {
    invoiceForm,
    editInvoice,
    toggleEdit,
    invoiceId,
    isLoadingRevert: isLoading,
    revertField,
  };
};

export const useInvoiceDetailsContext = () => {
  const context = useContext(InvoiceDetailsContext);
  if (!context) throw new Error('useInvoiceDetailsContext must be inside InvoiceDetailsProvider');
  return context;
};

export const InvoiceDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useInvoiceDetailsContextValue();
  return <InvoiceDetailsContext.Provider value={value}>{children}</InvoiceDetailsContext.Provider>;
};
