import { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const MuiTextField: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
} = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: () => ({
      '& .MuiInputBase-input': {
        height: '1.3rem',
      },
      '& .MuiInputBase-root': {
        borderRadius: '4px',
      },
    }),
  },
};
