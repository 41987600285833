import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApiInvoicesListQuery } from '@api/api';
import { ROUTING } from '@constants/routing';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import InvoicesTable from '@pages/Invoices/components/InvoicesTable';

import { Box, Button, Typography } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useApiInvoicesListQuery({
    limit,
    offset: page * limit,
  });
  const isLoading = [isLoadingInvoices, isFetching].some(Boolean);

  return (
    <Box aria-label="invoices page" display="flex" gap={4} flexDirection="column">
      <Box aria-label="title area" display="flex" justifyContent="space-between">
        <Typography variant="h4">{t('dashboard.navigation.invoices')}</Typography>
        <Button color="success" onClick={() => navigate(`/${ROUTING.INVOICE_UPLOAD}`)}>
          {t('dashboard.navigation.uploadInvoice')}
        </Button>
      </Box>

      <InvoicesTable
        invoices={invoicesData}
        isLoading={isLoading}
        handlePageChange={handlePaginationModelChange}
        paginationModel={{ page, pageSize: limit }}
      />
    </Box>
  );
};

export default memo(Invoices);
