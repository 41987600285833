export const ROUTING = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  CONFIRM_EMAIL: 'confirm-email',
  ROOT: '/',
  FORGOT_PASSWORD: 'forgot-password',
  CHECK_EMAIL: 'check-email',
  RESET_PASSWORD: 'reset-password',
  INVOICES: 'invoices',
  INVOICE_DETAILS: 'invoice-details',
  INVOICE_UPLOAD: 'invoice-upload',
  CREATE_ID: 'create-id',
};
