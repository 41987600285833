import { FC, memo, PropsWithChildren } from 'react';
import enUS from 'date-fns/locale/en-US';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DataPickerLocalizationProvider: FC<PropsWithChildren> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
    {children}
  </LocalizationProvider>
);

export default memo(DataPickerLocalizationProvider);
