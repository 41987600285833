export const API_ERROR_MSG_PATH = 'data.error.fields.non_field_errors';
export const COUNTDOWN_DATE = new Date(0);
export const HEADER_HEIGHT_DESKTOP = 150;
export const YES = 'Yes';
export const NO = 'No';
export const NOT_AVAILABLE = 'Not available';
export const DATAGRID_FOOTER_HEIGHT = 52;
export const TOOLTIP_MAX_CHARS = 15;
export const DEFAULT_PAGE_LIMIT = 25;
export const MAX_DAYS_IN_MONTH = 31;
export const MONTHS_IN_YEAR = 12;
export const INVOICE_ID = 'invoiceId';
export const MAX_UPLOAD_FILES = 10;
