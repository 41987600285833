import { FC, memo } from 'react';

import { CircularProgress, CircularProgressProps, IconButton, IconButtonProps } from '@mui/material';

interface LoadingIconButtonProps extends IconButtonProps {
  loading: boolean;
  loaderSize: string;
  loaderColor: CircularProgressProps['color'];
}

const LoadingIconButton: FC<LoadingIconButtonProps> = ({ loading, children, loaderSize, loaderColor, ...props }) => (
  <IconButton {...props}>{loading ? <CircularProgress size={loaderSize} color={loaderColor} /> : children}</IconButton>
);

export default memo(LoadingIconButton);
