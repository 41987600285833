import { SIGNUP_ACTION } from '@constants/auth';

export const authStorage = {
  getSignup() {
    const signupStatus = localStorage.getItem(SIGNUP_ACTION);

    return signupStatus || null;
  },

  setSignup(value: string) {
    localStorage.setItem(SIGNUP_ACTION, value);
  },

  removeSignup() {
    localStorage.removeItem(SIGNUP_ACTION);
  },
};
