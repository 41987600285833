export const ITEMS_FIELD = 'items';
export const RECIPIENT_FIELD = 'recipient';
export const VENDOR_FIELD = 'vendor';
export const INVOICE_DATE_FIELD = 'invoice_date';
export const DUE_DATE_FIELD = 'due_date';
export const UPDATED_AT_FIELD = 'updated_at';
export const SERVICE_START_DATE_FIELD = 'service_start_date';
export const SERVICE_END_DATE_FIELD = 'service_end_date';
export const IBN_FIELD = 'ibn';
export const CUSTOMER_NAME_FIELD = 'customer_name';
export const CUSTOMER_ADDRESS_FIELD = 'customer_address';
export const CURRENCY_FIELD = 'currency';
export const CURRENCY_SYMBOL_FIELD = 'currency_symbol';
export const SUB_TOTAL_FIELD = 'sub_total';
export const TOTAL_TAX_FIELD = 'total_tax';
export const TOTAL_AMOUNT_FIELD = 'total_amount';
export const DESCRIPTION_FIELD = 'description';
export const UNIT_PRICE_FIELD = 'unit_price';
export const QUANTITY_FIELD = 'quantity';
export const CURRENCY_CODE_FIELD = 'currency_code';
export const AMOUNT_FIELD = 'amount';
