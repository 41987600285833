import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_LIMIT } from '@constants/common';

export const usePageFilterUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +(searchParams.get('page') || '0');
  const limit = +(searchParams.get('limit') || String(DEFAULT_PAGE_LIMIT));

  return {
    page,
    limit,
    setPage: (value: number) => {
      searchParams.set('page', String(value));
      setSearchParams(searchParams);
    },
    setLimit: (value: number) => {
      searchParams.set('limit', String(value));
      setSearchParams(searchParams);
    },
  };
};
