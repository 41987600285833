import { FC, Fragment, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesChangesListApiResponse, InvoiceRead } from '@api/api';
import {
  AMOUNT_FIELD,
  CURRENCY_CODE_FIELD,
  CURRENCY_FIELD,
  CURRENCY_SYMBOL_FIELD,
  CUSTOMER_ADDRESS_FIELD,
  CUSTOMER_NAME_FIELD,
  DESCRIPTION_FIELD,
  DUE_DATE_FIELD,
  IBN_FIELD,
  INVOICE_DATE_FIELD,
  ITEMS_FIELD,
  QUANTITY_FIELD,
  RECIPIENT_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  SUB_TOTAL_FIELD,
  TOTAL_AMOUNT_FIELD,
  TOTAL_TAX_FIELD,
  UNIT_PRICE_FIELD,
  UPDATED_AT_FIELD,
  VENDOR_FIELD,
} from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsContext } from '@pages/InvoiceDetails/context';
import { StyledPaper, StyledTextField } from '@pages/InvoiceDetails/styled';
import { currencyCodes, currencySymbols, getChangedFields, getItemIndex } from '@pages/InvoiceDetails/utils';

import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Autocomplete, Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker } from '@mui/x-date-pickers';
import AutocompletePaper from '@components/AutocompletePaper';
import HistoryRevert from '@components/HistoryRevert';
import LoadingButton from '@components/LoadingButton';

const UpdateDetailsForm: FC<{
  data?: InvoiceRead;
  loading: boolean;
  changesData?: ApiInvoicesChangesListApiResponse;
}> = ({ data, loading, changesData }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('lg'));
  const isLgOrMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const changes = changesData && getChangedFields(changesData as ApiInvoicesChangesListApiResponse);
  const { revertField, isLoadingRevert } = useInvoiceDetailsContext();

  const getGridColumns = () => {
    if (isXl) return 'repeat(3, 1fr)';
    if (isLgOrMd) return 'repeat(2, 1fr)';
    return 'repeat(1, 1fr)';
  };

  return (
    <StyledPaper elevation={6}>
      <Typography variant="subtitle1">{t('dashboard.invoiceDetails.commonInfoTitle')}</Typography>
      <Box display="grid" gridTemplateColumns={getGridColumns()} gap={3} my={3}>
        <Controller
          control={control}
          name={RECIPIENT_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              disabled
              variant="outlined"
              label={t('dashboard.updateDetails.recipientField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'recipient input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(RECIPIENT_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(RECIPIENT_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={VENDOR_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.vendorField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'vendor input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(VENDOR_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(VENDOR_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={INVOICE_DATE_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              label={t('dashboard.updateDetails.invoiceDateField')}
              slots={{
                openPickerIcon: CalendarTodayRoundedIcon,
              }}
              slotProps={{
                textField: params => ({
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(INVOICE_DATE_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(INVOICE_DATE_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={DUE_DATE_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              label={t('dashboard.updateDetails.dueDateField')}
              slots={{
                openPickerIcon: CalendarTodayRoundedIcon,
              }}
              slotProps={{
                textField: params => ({
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(DUE_DATE_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(DUE_DATE_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={UPDATED_AT_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              disabled
              label={t('dashboard.updateDetails.updatedDateField')}
              slots={{
                openPickerIcon: CalendarTodayRoundedIcon,
              }}
              slotProps={{
                textField: params => ({
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(UPDATED_AT_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(UPDATED_AT_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={SERVICE_START_DATE_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              label={t('dashboard.updateDetails.serviceStartDateField')}
              slots={{
                openPickerIcon: CalendarTodayRoundedIcon,
              }}
              slotProps={{
                textField: params => ({
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(SERVICE_START_DATE_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(SERVICE_START_DATE_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={SERVICE_END_DATE_FIELD}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              label={t('dashboard.updateDetails.serviceEndDateField')}
              slots={{
                openPickerIcon: CalendarTodayRoundedIcon,
              }}
              slotProps={{
                textField: params => ({
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(SERVICE_END_DATE_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(SERVICE_END_DATE_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  },
                  variant: 'outlined',
                  error: Boolean(fieldState.error),
                  helperText:
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                }),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={IBN_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.abnField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'ibn input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(IBN_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(IBN_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={CUSTOMER_NAME_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.customerNameField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'customer name input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(CUSTOMER_NAME_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(CUSTOMER_NAME_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={CUSTOMER_ADDRESS_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.customerAddressField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'customer address input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(CUSTOMER_ADDRESS_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(CUSTOMER_ADDRESS_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          name={CURRENCY_FIELD}
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              options={currencyCodes}
              getOptionLabel={option => option}
              disablePortal
              disableClearable
              PaperComponent={AutocompletePaper}
              renderInput={params => (
                <TextField
                  error={Boolean(fieldState.error)}
                  variant="outlined"
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  label={t('dashboard.updateDetails.currencyCode')}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(CURRENCY_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(CURRENCY_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(_, d) => field.onChange(d)}
            />
          )}
        />
        <Controller
          name={CURRENCY_SYMBOL_FIELD}
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              options={currencySymbols}
              getOptionLabel={option => option}
              disablePortal
              disableClearable
              PaperComponent={AutocompletePaper}
              renderInput={params => (
                <TextField
                  error={Boolean(fieldState.error)}
                  variant="outlined"
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  label={t('dashboard.updateDetails.currencySymbol')}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="start">
                        {changes?.includes(CURRENCY_SYMBOL_FIELD) && (
                          <HistoryRevert handleRevert={() => revertField(CURRENCY_SYMBOL_FIELD)} />
                        )}
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(_, d) => field.onChange(d)}
            />
          )}
        />
        <Controller
          control={control}
          name={SUB_TOTAL_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.subTotalField')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'sub total input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(SUB_TOTAL_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(SUB_TOTAL_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={TOTAL_TAX_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.totalTaxTitle')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'total tax input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(TOTAL_TAX_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(TOTAL_TAX_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name={TOTAL_AMOUNT_FIELD}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              variant="outlined"
              label={t('dashboard.updateDetails.totalAmountTitle')}
              type="text"
              autoComplete="off"
              inputProps={{
                'aria-required': 'true',
                'aria-label': 'total amount input field',
              }}
              InputProps={{
                endAdornment: changes?.includes(TOTAL_AMOUNT_FIELD) && (
                  <HistoryRevert handleRevert={() => revertField(TOTAL_AMOUNT_FIELD)} />
                ),
              }}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...field}
            />
          )}
        />
      </Box>

      <Typography variant="subtitle1">{t('dashboard.invoiceDetails.servicesTitle')}</Typography>
      <Box display="grid" gridTemplateColumns={getGridColumns()} gap={3} my={3}>
        {data?.items?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <Controller
              control={control}
              name={`${ITEMS_FIELD}.${index}.${DESCRIPTION_FIELD}`}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  label={`${t('dashboard.updateDetails.descriptionTitle')} ${index + 1}`}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': `description ${index + 1} input field`,
                  }}
                  InputProps={{
                    endAdornment: index === getItemIndex(item.id, DESCRIPTION_FIELD, data?.items, changesData) && (
                      <HistoryRevert handleRevert={() => revertField(DESCRIPTION_FIELD, item.id)} />
                    ),
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name={`${ITEMS_FIELD}.${index}.${UNIT_PRICE_FIELD}`}
              render={({ field, fieldState }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={`${t('dashboard.updateDetails.unitPriceTitle')} ${index + 1}`}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': `unit price ${index + 1} input field`,
                  }}
                  InputProps={{
                    endAdornment: index === getItemIndex(item.id, UNIT_PRICE_FIELD, data?.items, changesData) && (
                      <HistoryRevert handleRevert={() => revertField(UNIT_PRICE_FIELD, item.id)} />
                    ),
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  onKeyDown={e => {
                    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => (e.target as HTMLElement).blur()}
                />
              )}
            />
            <Controller
              control={control}
              name={`${ITEMS_FIELD}.${index}.${QUANTITY_FIELD}`}
              render={({ field, fieldState }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={`${t('dashboard.updateDetails.quantityTitle')} ${index + 1}`}
                  type="number"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': `quantity ${index + 1} input field`,
                  }}
                  InputProps={{
                    endAdornment: index === getItemIndex(item.id, QUANTITY_FIELD, data?.items, changesData) && (
                      <HistoryRevert handleRevert={() => revertField(QUANTITY_FIELD, item.id)} />
                    ),
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  onKeyDown={e => {
                    if (
                      e.key === 'e' ||
                      e.key === 'E' ||
                      e.key === '-' ||
                      e.key === '+' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => (e.target as HTMLElement).blur()}
                />
              )}
            />
            <Controller
              name={`${ITEMS_FIELD}.${index}.${CURRENCY_CODE_FIELD}`}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={currencyCodes}
                  getOptionLabel={option => option}
                  disablePortal
                  disableClearable
                  PaperComponent={AutocompletePaper}
                  renderInput={params => (
                    <TextField
                      error={Boolean(fieldState.error)}
                      variant="outlined"
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      label={`${t('dashboard.updateDetails.currencyCode')} ${index + 1}`}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="start">
                            {index === getItemIndex(item.id, CURRENCY_CODE_FIELD, data?.items, changesData) && (
                              <HistoryRevert handleRevert={() => revertField(CURRENCY_CODE_FIELD, item.id)} />
                            )}
                            {params.InputProps.endAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, d) => field.onChange(d)}
                />
              )}
            />
            <Controller
              name={`${ITEMS_FIELD}.${index}.${CURRENCY_SYMBOL_FIELD}`}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={currencySymbols}
                  getOptionLabel={option => option}
                  disablePortal
                  disableClearable
                  PaperComponent={AutocompletePaper}
                  renderInput={params => (
                    <TextField
                      error={Boolean(fieldState.error)}
                      variant="outlined"
                      helperText={
                        fieldState.error &&
                        t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                      }
                      label={`${t('dashboard.updateDetails.currencySymbol')} ${index + 1}`}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="start">
                            {index === getItemIndex(item.id, CURRENCY_SYMBOL_FIELD, data?.items, changesData) && (
                              <HistoryRevert handleRevert={() => revertField(CURRENCY_SYMBOL_FIELD, item.id)} />
                            )}
                            {params.InputProps.endAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, d) => field.onChange(d)}
                />
              )}
            />
            <Controller
              control={control}
              name={`${ITEMS_FIELD}.${index}.${AMOUNT_FIELD}`}
              render={({ field, fieldState }) => (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={`${t('dashboard.updateDetails.totalPriceTitle')} ${index + 1}`}
                  type="text"
                  autoComplete="off"
                  inputProps={{
                    'aria-required': 'true',
                    'aria-label': `amount ${index + 1} input field`,
                  }}
                  InputProps={{
                    endAdornment: index === getItemIndex(item.id, AMOUNT_FIELD, data?.items, changesData) && (
                      <HistoryRevert handleRevert={() => revertField(AMOUNT_FIELD, item.id)} />
                    ),
                  }}
                  error={Boolean(fieldState.error)}
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  onKeyDown={e => {
                    if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => (e.target as HTMLElement).blur()}
                />
              )}
            />
          </Fragment>
        ))}
      </Box>

      <LoadingButton aria-label="submit button" type="submit" loading={loading} disabled={isLoadingRevert}>
        {t('common.submit')}
      </LoadingButton>
    </StyledPaper>
  );
};

export default memo(UpdateDetailsForm);
