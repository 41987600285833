import { forwardRef } from 'react';

import Box from '@mui/material/Box';

const SvgColor = forwardRef(({ src, sx, ...other }: any, ref) => (
  <Box
    component="span"
    className="svg-color"
    ref={ref}
    width={24}
    height={24}
    display="inline-block"
    bgcolor="currentColor"
    sx={{
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
    {...other}
  />
));

export default SvgColor;
